* {
  box-sizing: border-box;
}
.special_font {
  font-family: 'Quicksand', sans-serif;
}
.avatar {
  margin:auto;
  min-width:80px;
  min-height:80px;
  max-width:200px;
  max-height:200px;
  
}
.avatar img {
  border: 5px solid black;
  border-radius: 50%;
}
div img {
  width:100%;
  height:100%;
}
.skill {
  width:60px;
  height:60px;
  border:1px solid black;
  border-radius: 5px;
  padding:5px;
}
.timeline .circle0{
  display:inline-block;
  width:16px;
  height:16px;
  margin-top:14px;
  margin-bottom:0px;
  margin-left:7px;
  margin-right:7px;
  border-radius: 50%;
  background-color: red;
  box-shadow: inset 0 0 0 1000px red;
  position:relative;
  z-index:2;
}
.timeline .circle{
  display:inline-block;
  width:16px;
  height:16px;
  margin-top:14px;
  margin-bottom:0px;
  margin-left:7px;
  margin-right:7px;
  background-color: rgb(4,125,197);
  box-shadow: inset 0 0 0 1000px rgb(4,125,197);
  position:relative;
  z-index:2;
  transform:translate(0em) skew(-30deg);
}
.timeline .v_ligne {
  height:100%;
  min-height: 100%;
  width:5px;
  margin:auto;
  background-color: black;
  box-shadow: inset 0 0 0 1000px black;
  display:block;
  position:relative;
  top:-20px;
  z-index:1;
}

.time_li {
  left:-36px;
}
section {
  margin-bottom:1em;
}
.section_title  h2 {
  font-size: 2em;
  text-align: center;
  margin-top: 0.1em;
  font-family: 'Quicksand', sans-serif;
}

.section_title {
  width: 100%;
  text-align: center;
  color:black;
}
.title_box {
    display: inline-block;
    margin: 0 auto;
    padding: 3px;
}

.title_deco {
  position:absolute;
  height: 3em;
  transform:translate(-1.5em) skew(-30deg);
  z-index:-1;
  
  background: rgba(255, 255, 255, 0.466);
  border-top:1px solid rgb(4,125,197);
  border-bottom:1px solid rgb(4,125,197);  
  border-left:10px solid rgb(4,125,197);
  border-right:10px solid #047dc5;

}
.section_content {
    background-color: aliceblue;
}
.info {
  display:flex;
  align-items: center;
}
.info i {
  width:30px;
  height:30px;
  margin:10px;
}

.hobby_ico {
  width:60px;
  height:60px;
  border:1px solid black;
  border-radius: 5px;
  padding:5px;  
}

#site_web {
  display:none
}
@media print {
  body {
    position:   fixed;
    top:        0mm;
    left:       0mm;
    width:      190mm;
    height:     277mm;
    margin:     0mm 0mm 0mm 0mm;
    /*padding:    20mm 20mm 20mm 20mm;*/
    /*border:     solid green 1px;
    outline:    solid black 1px;*/
  }
  h1 {
    font-size:20pt;
  }
  nav {
    display:none;
  }
  .navbar {
    display:none;
  }
  /* ENTETE */
  #head {
    display:block;
  }
  #avatar {
    position:absolute;
    display:block;
    top:0.3cm;
    left:1cm;
    width:4cm;
  }
  #site_web {
    display:block;
    position:absolute;
    background-color: white;
    text-align: center;
    left:0.5cm;
    top:3cm;
    width:3cm;
  }
  #main_title {
    position:absolute;
    display:block;
    top:0;
    left:5cm;
    width:14cm;
  }
  #main_title_job {
    font-size:20pt;
  }
  #profil {
    position:absolute;
    display:block;
    top:4cm;
    left:0cm;
    width:10cm;
  }
  .info p {
    font-size:10pt;
  }
  #skills {
    position:absolute;
    display:block;
    top:4cm;
    left:10cm;
    width:9cm;
  }
  #exps {
    position:absolute;
    display:block;
    top:12cm;
    left:0cm;
    width:9.5cm;
  }
  #formation {
    position:absolute;
    display:block;
    top:12cm;
    left:9.5cm;
    width:9.5cm;
  }
  .timeline .circle{
    margin-top: 0;
  }
  #timeline_content ul {
    display:none;
  }
  #timeline_content {
    font-size: 10pt;
  }
  #timeline_content h2{
    font-size: 10pt;
  }  
  #hobbies {
    position:absolute;
    display:block;
    top:23cm;
    left:0cm;
    width:19cm;
  }
}